import "swiper/swiper.min.css";
import React, { useEffect } from "react";
import { useState } from "react";
import { serverLink } from "../../../resources/url";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setHover] = useState(false);
  const [isHoveredNexButton, setHoverNextButton] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % props.sliderList.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % props.sliderList.length);
  };

  const handlePrev = () => {
    setCurrentSlide(
      (prevSlide) =>
        (prevSlide - 1 + props.sliderList.length) % props.sliderList.length
    );
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    maxWidth: "1902px",
    margin: "0 auto",
    overflow: "hidden",
  };

  const slideStyle = {
    position: "relative",
    width: "100%",
    height: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const contentStyle = {
    position: "absolute",
    left: "20px",
    width: "30%",
    top: "250px",
    color: "white",
    zIndex: 1,
    textAlign: "left",
    margin: "0 120px",
  };

  const buttonNextPrevStyle = {
    position: "absolute",
    top: "50%",
    padding: "10px",
    background: isHoveredNexButton ? "#0369c4" : "rgba(255,255,255,0)",
    color: isHoveredNexButton ? "#fff" : "#131313",
    cursor: "pointer",
    outline: "none",
    fontWeight: "400",
    border: "1px solid #0369c4",
    letterSpacing: "3px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
  };

  const buttonStyles = {
    background: isHovered ? "#fff" : "#0369c4",
    color: isHovered ? "#131313" : "#fff",
    display: "inline-block",
    padding: "18px 44px",
    fontFamily: '"Poppins", sans-serif',
    fontSize: "14px",
    fontWeight: "400",
    border: "1px solid #0369c4",
    letterSpacing: "3px",
    textAlign: "center",
    textTransform: "uppercase",
    cursor: "pointer",
  };

  //
  // const containerStyleMobile = {
  //   position: "relative",
  //   width: "100%",
  //   height: "100%",
  //   maxWidth: "420px",
  //   maxHeight: "390px",
  //   margin: "0 auto",
  //   overflow: "hidden",
  // };

  const containerStyleMobile = {
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "420px",
    maxHeight: "390px",
    margin: "0 auto",
    overflow: "hidden",
    overflowY: "scroll", // Enable vertical scrolling
    WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS devices
  };

  const slideStyleMobile = {
    position: "relative",
    width: "100%",
    height: "700px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const contentStyleMobile = {
    position: "absolute",
    left: "20px",
    width: "15%",
    top: "50px",
    color: "white",
    zIndex: 1,
    textAlign: "left",
    margin: "0 15px",
  };

  const buttonNextPrevStyleMobile = {
    position: "absolute",
    top: "50%",
    padding: "10px",
    background: isHoveredNexButton ? "#0369c4" : "rgba(255,255,255,0)",
    color: isHoveredNexButton ? "#fff" : "#131313",
    cursor: "pointer",
    outline: "none",
    fontWeight: "400",
    border: "1px solid #0369c4",
    letterSpacing: "3px",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "12px",
  };

  const buttonStylesMobile = {
    background: isHovered ? "#fff" : "#0369c4",
    color: isHovered ? "#131313" : "#fff",
    display: "inline-block",
    padding: "18px 44px",
    fontFamily: '"Poppins", sans-serif',
    fontSize: "10px",
    fontWeight: "400",
    border: "1px solid #0369c4",
    letterSpacing: "3px",
    textAlign: "center",
    textTransform: "uppercase",
    cursor: "pointer",
  };

  return (
    <>
      {isMobile ? (
        <div style={containerStyleMobile}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={currentSlide}
            onChange={(index) => setCurrentSlide(index)}
            width={1902}
            dynamicHeight={false}
          >
            {props.sliderList.map((item, index) => (
              <div key={index} style={slideStyleMobile}>
                <div style={contentStyleMobile}>
                  <img
                    src={`${serverLink}public/uploads/sliders_uploads/${item.image}`}
                    alt={`Slide ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <h2>{item.title}</h2>
                  {/*<p>{item.caption}</p>*/}
                  <a
                    href={item.btnLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={buttonStylesMobile}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                  >
                    {item.btnName}
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <div style={containerStyle}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={currentSlide}
            onChange={(index) => setCurrentSlide(index)}
            width={1902}
            dynamicHeight={false}
          >
            {props.sliderList.map((item, index) => (
              <div key={index} style={slideStyle}>
                <img
                  src={`${serverLink}public/uploads/sliders_uploads/${item.image}`}
                  alt={`Slide ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <div style={contentStyle}>
                  <h2>{item.title}</h2>
                  <p>{item.caption}</p>
                  <a
                    href={item.btnLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={buttonStyles}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                  >
                    {item.btnName}
                  </a>
                </div>
              </div>
            ))}
          </Carousel>

          <button
            style={{ ...buttonNextPrevStyle, left: "10px" }}
            onClick={handlePrev}
            onMouseOver={() => setHoverNextButton(true)}
            onMouseOut={() => setHoverNextButton(false)}
          >
            <i className="fa fa-angle-left"></i>
          </button>
          <button
            style={{ ...buttonNextPrevStyle, right: "10px" }}
            onClick={handleNext}
            onMouseOver={() => setHoverNextButton(true)}
            onMouseOut={() => setHoverNextButton(false)}
          >
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
      )}
    </>
  );
};

export default Slider;
