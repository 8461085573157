import React, {useEffect} from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import NavigationBar from "../common/navigation/navigationbar";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Homepage from "../homepage/homepage";
import Footer from "../common/footer/footer";
import NotFound from "../404notfound/404notfound";
import {browserName, isMobile} from "react-device-detect";
import axios from "axios";
import {serverLink} from "../../resources/url";
import News from "../news/news";
import About from "../about/about";
import Contact from "../contact/contact";
import Services from "../services/services";
import Doctors from "../doctors/doctors";
import Gallery from "../gallery/gallery";
import Signup from "../authentication/signup";
import Login from "../authentication/login";
import ServiceDetails from "../services/service-details";
import DoctorDetails from "../doctors/doctor-details";
import NewsDetails from "../news/news-details";
import ForgotPassword from "../authentication/forgot-password";


export default function PageRoutes() {
    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then(async (result)=> {
            await getVisitorData(result.data);
        }).catch((e)=>{
            // console.log("Error Getting User Details")
        })

    }

    const getVisitorData = async (locData) => {
        let sendData = {
            IPAddress: locData.IPv4,
            Country: locData.country_name,
            lat: locData.latitude,
            lng: locData.longitude,
            IsMobileRequest: isMobile ? "1" : "0",
            UserAgent: browserName,
        }

        await axios.post(`${serverLink}general/website/visit-hit`, sendData).then(data => {

        }).catch(err => {
            console.log("Error Adding User Details")
        });
    };

    useEffect(() => {
     //   getLocationData();
    }, []);

    return (
        <Routers>
            <NavigationBar />

            <Routes>
                <Route path={"/"} element={<Homepage />} />
                <Route path={"/services"} element={<Services />} />
                <Route path={"/service/:slug"} element={<ServiceDetails />} />
                <Route path={"/doctors"} element={<Doctors />} />
                <Route path={"/doctor/:slug"} element={<DoctorDetails />} />
                <Route path={"/gallery"} element={<Gallery />} />
                <Route path={"/signup"} element={<Signup />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/forgot-password"} element={<ForgotPassword />} />
                <Route path={"/news"} element={<News />} />
                <Route path={"/news/:slug"} element={<NewsDetails />} />
                <Route path={"/about"} element={<About />} />
                <Route path={"/contact"} element={<Contact />} />

                {/*Application End*/}
                <Route path={"*"} element={<NotFound />} />
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
            <Footer />
        </Routers>
    );
}
