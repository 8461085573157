import storage from "redux-persist/lib/storage";
import {
    DepartmentListDetailsReducer,
    NewsDetailsReducer,
    GeneralDetailsReducer,
    HomepageContentDetailsReducer,
    SliderDetailsReducer,
    GalleryDetailsReducer,
} from "./detailsReducer";
import { projectCode } from "../resources/url";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    DepartmentListDetails: DepartmentListDetailsReducer,
    NewsDetails: NewsDetailsReducer,
    GeneralDetails: GeneralDetailsReducer,
    HomepageContentDetails: HomepageContentDetailsReducer,
    SliderDetails: SliderDetailsReducer,
    GalleryDetails: GalleryDetailsReducer,
});

const persistConfig = {
    key: projectCode,
    storage,
};

export default persistReducer(persistConfig, rootReducer);