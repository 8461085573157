import React from "react";
import {Link} from "react-router-dom";

export default function PageTitle (props) {
    return (
            <div className="bradcam_area  " style={{backgroundImage: `url(../img/banner/banner04.jpeg)`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="bradcam_text">
                                <h3>{props.title}</h3>
                                <p className="text-capitalize"><Link to="/">Home /</Link> {props.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}
