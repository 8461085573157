import React from "react";
import { projectName } from "../../../resources/url";
import { Helmet } from "react-helmet";

export default function PageName(props) {
  const page =
    typeof props.page !== "undefined"
      ? props.page !== ""
        ? " | " + props.page
        : ""
      : "";
  return (
    <Helmet>
      <title>{`${projectName} ${page}`}</title>
    </Helmet>
  );
}
