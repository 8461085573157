import { projectCode } from "../resources/url";

export const setDepartmentListDetails = (p) => {
    return {
        type: `SET_${projectCode}_DEPARTMENT_LIST_DETAILS`,
        payload: p,
    };
};

export const setNewsDetails = (p) => {
    return {
        type: `SET_${projectCode}_NEWS_DETAILS`,
        payload: p,
    };
};

export const setGeneralDetails = (p) => {
    return {
        type: `SET_${projectCode}_GENERAL_DETAILS`,
        payload: p,
    };
};

export const setHomepageContentDetails = (p) => {
    return {
        type: `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`,
        payload: p,
    };
};

export const setHomeSliderDetails = (p) => {
    return {
        type: `SET_${projectCode}_HOME_SLIDER_DETAILS`,
        payload: p,
    };
};

export const setGalleryDetails = (p) => {
    return {
        type: `SET_${projectCode}_GALLERY_DETAILS`,
        payload: p,
    };
};