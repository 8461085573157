import PageTitle from "../common/pagetitle/pagetitle";
import { gallery_category, gallery_data } from "../../resources/gallery_data";

export default function Gallery() {
  return (
    <>
      <PageTitle title="Gallery" />
      <div className="container">
        <div className="col-md-12 pt-5 mb-5">
          <h3>Image Gallery</h3>
          <div className="business_expert_area mt-3">
            <p>Gallery data will be available soon!</p>
            {/*<div className="business_tabs_area">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-xl-12">*/}
            {/*                <ul className="nav" id="myTab" role="tablist" style={{padding: '8px'}}>*/}
            {/*                    <li className="nav-item">*/}
            {/*                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home"*/}
            {/*                           role="tab"*/}
            {/*                           aria-controls="home"*/}
            {/*                           aria-selected="true" style={{padding: '5px', fontWeight: 'bold', color: '#555555'}}>All</a>*/}
            {/*                    </li>*/}
            {/*                    {*/}
            {/*                        gallery_category.length > 0 && gallery_category.map((item, index)=>{*/}
            {/*                            const slug = item.categoryName.trim().replace(/\s+/g, '-').toLowerCase();*/}
            {/*                            return (*/}
            {/*                                <li key={index} className="nav-item">*/}
            {/*                                    <a className="nav-link" id={`${slug}-tab`} data-toggle="tab"*/}
            {/*                                       href={`#${slug}`}*/}
            {/*                                       role="tab"*/}
            {/*                                       aria-controls={slug}*/}
            {/*                                       aria-selected="false" style={{padding: '5px', fontWeight: 'bold', color: '#555555'}}>{item.categoryName}</a>*/}
            {/*                                </li>*/}
            {/*                            )*/}
            {/*                        })*/}
            {/*                    }*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="container">*/}
            {/*    <div className="border_bottom">*/}
            {/*        <div className="tab-content" id="myTabContent">*/}
            {/*            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">*/}
            {/*                <div className="row gallery-item">*/}
            {/*                    {*/}
            {/*                        gallery_data.length > 0 ? gallery_data.map((element, key) => {*/}
            {/*                            return (*/}
            {/*                                <div key={key} className="col-md-4">*/}
            {/*                                    <a href={element.image} className="img-pop-up">*/}
            {/*                                        <div className="single-gallery-image"*/}
            {/*                                             style={{background: `url(${element.image})`}}></div>*/}
            {/*                                    </a>*/}
            {/*                                </div>*/}
            {/*                            )*/}
            {/*                        }) : <div className="col-md-12 text-center"><h3>No Image Found</h3></div>*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            {*/}
            {/*                gallery_category.length > 0 && gallery_category.map((item, index) => {*/}
            {/*                    const slug = item.categoryName.trim().replace(/\s+/g, '-').toLowerCase();*/}
            {/*                    return (*/}
            {/*                        <div key={index} className="tab-pane fade" id={slug} role="tabpanel" aria-labelledby={`${slug}-tab`}>*/}
            {/*                            <div className="row gallery-item">*/}
            {/*                                {*/}
            {/*                                    gallery_data.filter(e=>e.categoryID === item.categoryID).length > 0 ? gallery_data.filter(e=>e.categoryID === item.categoryID).map((element, key) => {*/}
            {/*                                        return (*/}
            {/*                                            <div key={key} className="col-md-4">*/}
            {/*                                                <a href={element.image} className="img-pop-up">*/}
            {/*                                                    <div className="single-gallery-image"*/}
            {/*                                                         style={{background: `url(${element.image})`}}></div>*/}
            {/*                                                </a>*/}
            {/*                                            </div>*/}
            {/*                                        )*/}
            {/*                                    }) : <div className="col-md-12 text-center"><h3>No Image Found</h3></div>*/}
            {/*                                }*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
}
