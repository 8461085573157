import slider1 from "../images/slide1.jpg";
import slider8 from "../images/slide8.jpg";
export const servicesData = [
  {
    id: 1,
    title: "Accident & Emergency",
    icon: "fa-ambulance",
    caption: "Providing Swift and Life-saving Care During Emergencies",
    text: `Explore Da-Rose Ikonne Hospital Ambulance Service, a reliable lifeline during critical moments. Our fleet of state-of-the-art ambulances is equipped to deliver swift and life-saving care 24/7. Our highly skilled team ensures immediate and comprehensive medical interventions, prioritizing patient safety and comfort. Partner with us for unparalleled care and support during times of crisis.`,
  },
  {
    id: 2,
    title: "General Consultation",
    icon: "fa-user-doctor",
    caption:
      "Discover Comprehensive Healthcare Consultation at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital, a distinguished institution offering comprehensive general consultation services. Our modern facility, combined with a patient-centered approach, ensures exceptional care tailored to your unique needs. Whether it's routine check-ups or specialized consultations, trust us for a seamless and holistic healthcare experience.`,
  },
  {
    id: 3,
    title: "Dental Care",
    icon: "fa-tooth",
    caption: "Experience Outstanding Dental Care at Enyimba Hospital",
    text: `Explore Da-Rose Ikonne Hospital's  exceptional dental service, dedicated to enhancing your smile and overall oral health. Our skilled and compassionate dental professionals provide a comprehensive range of services, ensuring a positive and comfortable dental experience. Take the first step towards a healthier, more beautiful smile with our personalized care.`,
  },
  {
    id: 4,
    title: "Physiotherapy Department",
    icon: "fa-heart-pulse",
    caption:
      "Holistic Physiotherapy Consultations for Optimal Physical Well-being",
    text: `Da-Rose Ikonne Hospital's  Physiotherapy Department, a center of excellence committed to delivering exceptional care for your physical well-being. Our highly skilled and experienced physiotherapists offer personalized consultations, addressing a wide range of musculoskeletal conditions and promoting overall wellness. Trust us to be your partners in achieving optimal physical health and well-being.`,
  },
  {
    id: 5,
    title: "General Surgery Service",
    icon: "fa-scissors",
    caption: "Exceptional Surgical Care for a Healthier Tomorrow",
    text: `Da-Rose Ikonne Hospital's  General Surgery service provides comprehensive and state-of-the-art surgical care. Our highly skilled surgeons, utilizing advanced technology and innovative techniques, prioritize patient safety and well-being. Trust us for exceptional surgical outcomes and contribute to the growth of medical education and research.`,
  },
  {
    id: 6,
    title: "Radiography Service",
    icon: "fa-microscope",
    caption: "Cutting-edge Imaging for Precise Diagnostics",
    text: `Explore Da-Rose Ikonne Hospital's  Radiography Service, where advanced imaging technology meets expert medical professionals. Our diagnostic tools and skilled radiologists ensure precise and reliable results, creating a warm and reassuring environment for your imaging experience. Trust us with your medical imaging needs for accurate diagnoses and exceptional healthcare outcomes.`,
  },
  {
    id: 8,
    title: "Obstetrics and Gynaecology Services",
    icon: "fa-bed",
    caption:
      "Discover Unmatched Obstetrics and Gynaecology Care at Enyimba Hospital",
    text: `Enyimba Hospital is committed to delivering exceptional care and support for women's health through our distinguished Obstetrics and Gynaecology service. Our world-class facility is purposefully designed to meet the unique needs of women at every stage of life, ensuring their health and well-being.<br/><br/> Our highly skilled obstetricians and gynaecologists employ the latest medical advancements and techniques to provide comprehensive care, whether it's routine prenatal care, family planning guidance, reproductive health assistance, or specialized treatment for complex gynaecological conditions.`,
  },
  {
    id: 9,
    title: "Ophthalmology Services",
    icon: "fa-eye",
    caption: "Explore Comprehensive Ophthalmology Services at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's esteemed Ophthalmology Service, where clarity and excellence define vision care. Our state-of-the-art facility is dedicated to providing exceptional medical services, ensuring the visual health of our esteemed patients. With a team of highly skilled ophthalmologists, optometrists, and dedicated support staff, we have created an environment that upholds the highest standards of care and compassion.`,
  },
  {
    id: 10,
    title: "Paediatric Care",
    icon: "fa-baby",
    caption: "Experience Excellence in Paediatric Services at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's  Paediatric Care service, prioritizing the health and well-being of our youngest patients. Our state-of-the-art facility, nestled within the picturesque campus of Enyimba Hospital in Abuja, is dedicated to providing world-class medical care tailored specifically for children and adolescents. Led by a team of highly skilled paediatricians, nurses, and support staff, we are committed to delivering exceptional healthcare with a compassionate touch.`,
  },
  {
    id: 11,
    title: "Orthopaedic Surgery",
    icon: "fa-wheelchair",
    caption: "Discover Excellence in Orthopaedic Surgery at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's  Orthopaedic Surgery service, where precision meets compassion to redefine the future of musculoskeletal care. With a commitment to excellence and a patient-centric approach, our world-class team of highly skilled surgeons and dedicated medical professionals is at the forefront of delivering exceptional orthopaedic care.`,
  },
  {
    id: 12,
    title: "Eye Care",
    icon: "fa-eye",
    caption: "Experience Comprehensive Eye Care at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's  comprehensive Eye Care Service, where we prioritize your vision health with cutting-edge technology and expert care. Our state-of-the-art facility is designed to provide exceptional eye care services, ensuring the well-being of your eyes. Our team of highly skilled ophthalmologists, optometrists, and dedicated support staff are committed to delivering the highest standard of care with compassion and precision.`,
  },
  {
    id: 13,
    title: "Physical Therapy",
    icon: "fa-bed-pulse",
    caption: "Experience Comprehensive Physical Therapy at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's  Physical Therapy Department, a distinguished center of excellence dedicated to delivering exceptional care and support for your physical well-being. Our state-of-the-art facility, situated within the esteemed Enyimba Hospital in Abuja, is committed to providing top-notch medical services to the nation while nurturing and empowering the next generation of medical professionals. Our team of highly skilled and experienced physical therapists brings a wealth of expertise and a passion for delivering personalized care that is tailored to your specific needs.<br/><br/> We prioritize your comfort and ensure that every step of your journey with us is met with compassion, understanding, and unwavering support.

Whether you are seeking rehabilitation after an injury, relief from chronic pain, or guidance in enhancing your physical performance, our state-of-the-art facilities and cutting-edge treatment modalities are designed to help you achieve optimal results. We believe in a holistic approach to physical therapy, combining evidence-based practices with the latest advancements in technology to deliver effective and efficient care.<br/><br/>

When you choose Enyimba Hospital's Physical Therapy Department, you can expect an individualized treatment plan that considers your unique circumstances, goals, and aspirations. Our physical therapists will conduct a thorough assessment to understand the root cause of your condition and then work closely with you to develop a customized treatment strategy that addresses your specific needs. Throughout your journey, we will be your trusted partners, providing guidance, education, and ongoing support to help you achieve lasting improvements in your physical health.<br/><br/>

Our commitment to excellence extends beyond our expertise in physical therapy. We have created a warm and welcoming environment where you will feel at ease and supported throughout your visits. Our friendly and professional staff are dedicated to ensuring your comfort and maintaining the highest standards of patient care.<br/><br/>

At Da-Rose Ikonne Hospital Physical Therapy Department, we firmly believe in the transformative power of physical therapy and its ability to enhance your quality of life. We invite you to take the first step on your wellness journey today by discovering the comprehensive range of services we offer, from manual therapy and exercise prescription to innovative rehabilitation programs. Trust us to be your partners in achieving optimal physical health and well-being. Elevate your wellness journey with Enyimba Hospital's Physical Therapy Department today!`,
  },
  {
    id: 14,
    title: "Skin Surgery",
    icon: "fa-viruses",
    caption: "Experience Advanced Skin Surgery at Enyimba Hospital",
    text: `Welcome to Da-Rose Ikonne Hospital's  advanced Skin Surgery service, where expertise meets innovation to address a wide range of dermatological concerns. Our state-of-the-art facility is dedicated to providing exceptional medical services, ensuring optimal skin health and enhancing your overall well-being. Our team of highly skilled dermatologic surgeons and support staff is committed to delivering personalized and comprehensive care.`,
  },
  {
    id: 15,
    title: "Surgery Services",
    icon: "fa-kit-medical",
    caption: "Exceptional Surgical Care for a Healthier Tomorrow",
    text: `At Da-Rose Ikonne Hospital, we take pride in offering comprehensive and state-of-the-art Surgery Services dedicated to providing exceptional surgical care to our patients. With a team of highly skilled surgeons and cutting-edge technology, we strive to ensure the best possible outcomes for every individual who entrusts their health to us.`,
  },
  {
    id: 16,
    title: "Diagnostic Test Service",
    icon: "fa-stethoscope",
    caption: "Explore Advanced Diagnostic Testing at Enyimba Hospital",
    text: `EDa-Rose Ikonne Hospital Diagnostic Test Service brings cutting-edge technology and expert medical professionals together to offer precise diagnostic solutions and exceptional patient care. As a premier hospital built by Enyimba University Abuja, we are dedicated to providing the highest standard of medical services, supporting our university's medical students, and delivering top-notch healthcare to the nation.<br/><br/>

Our Diagnostic Test Service features state-of-the-art imaging equipment, including modern CT scans and ultrasound machines. Equipped with the latest technological advancements, our diagnostic tools enable our highly skilled radiologists to capture detailed and comprehensive images, aiding in the accurate diagnosis and effective treatment of a wide range of medical conditions.<br/><br/>

Whether you need a CT scan for detailed internal structure assessment or an ultrasound examination for focused evaluation, our dedicated team is here to provide unparalleled care and attention. Our radiographers and radiologists possess extensive expertise in conducting various imaging procedures, ensuring that you receive precise and reliable results.<br/><br/>

At Enyimba Hospital, we prioritize patient comfort and safety in our Diagnostic Test Service. Our friendly and compassionate staff members strive to create a warm and reassuring environment throughout your imaging experience. We understand that undergoing medical procedures can be overwhelming, which is why we take the time to address your concerns, explain the process thoroughly, and provide personalized support at every step.<br/><br/>

When you choose our Diagnostic Test Service, you benefit from our commitment to continuous innovation and excellence. Our state-of-the-art equipment and advanced imaging techniques enable us to capture high-resolution images with exceptional clarity, facilitating early detection and accurate assessment of various medical conditions. This, in turn, allows our expert medical team to develop tailored treatment plans, resulting in improved patient outcomes and enhanced overall well-being.<br/><br/>

As part of the larger Enyimba Hospital, our Diagnostic Test Service seamlessly integrates with other departments and specialties, ensuring comprehensive and coordinated care. Our multidisciplinary approach fosters collaboration among healthcare professionals, leading to efficient diagnoses, effective treatment strategies, and holistic patient management.<br/><br/>

Experience the difference of our world-class Diagnostic Test Service at Enyimba Hospital. With cutting-edge technology, a skilled team of professionals, and a patient-centered approach, we are dedicated to providing you with the highest quality imaging services for accurate diagnoses, personalized treatment plans, and exceptional healthcare outcomes. Trust us with your medical imaging needs, and let us help you take proactive steps towards a healthier future.<br/><br/>`,
  },
];

export const news_data = [
  {
    newsID: "1",
    title: "Commissioning Enyimba Hospital for the next",
    description:
      "<p>President Buhari gave the charge on Saturday in Abuja at the 7th and 8th convocation ceremony of the Baze University, 10th-anniversary celebration and commissioning of the university’s 200 bed ultramodern Teaching Hospital.</P>\n<p>President Buhari, represented by the Minister of Special Duties and Intergovernmental Affairs, Senator George Akume, also urged the graduands to exhibit character and learning while accessing the country’s wider interventions and initiatives near them.</p>\n<p>He congratulated the founder of the university, Senator Yusuf Datti Baba Ahmed, for his excellent vision and commitment to the education and health sectors.</p>\n<p>The President also appreciated the contributions of the private sector in the development of the education sector in the country.</p>\n<p>He assured Nigerians of the government’s continuous commitment and support in opening the education and health sectors by providing an enabling environment.\nNigerians can now earn dollars LEGITIMATELY by participating in the premium domain business; our clients acquired a premium domain for $700 and profited $13,000 within 3 weeks. Click here see how you too can do it.\n</p>\n",
    image: slider8,
    status: "published",
    viewCount: "5",
    submittedBy: "HOOO1",
    submittedOn: "2023-09-04 15:08:25",
    updatedBy: "HOOO1",
    updatedOn: "",
  },
  {
    newsID: "2",
    title:
      "Nigerians of the government’s continuous commitment and support in opening the education and health",
    description:
      "<p>He assured Nigerians of the government’s continuous commitment and support in opening the education and health sectors by providing an enabling environment.\nNigerians can now earn dollars LEGITIMATELY by participating in the premium domain business; our clients acquired a premium domain for $700 and profited $13,000 within 3 weeks. Click here see how you too can do it.\n</p>\n",
    image: slider1,
    status: "published",
    viewCount: "2",
    submittedBy: "H0001",
    submittedOn: "2023-09-04 15:08:25",
    updatedBy: "H0001",
    updatedOn: "",
  },
];
