import { Link } from "react-router-dom";
import { projectPhone } from "../../../resources/url";

export default function Badge() {
  return (
    <div className="service_area">
      <div className="container p-0">
        <div className="row no-gutters">
          <div className="col-xl-4 col-md-4">
            <div className="single_service">
              <div className="icon">
                <i className="flaticon-electrocardiogram"></i>
              </div>
              <h3>Hospitality</h3>
              <p>
                Your Comfort Matters: Where Hospitality Meets Healing in Our
                Hospital.
              </p>
              <Link to="/services" className="boxed-btn3-white">
                Explore Our Services
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-md-4">
            <div className="single_service">
              <div className="icon">
                <i className="flaticon-emergency-call"></i>
              </div>
              <h3>Emergency Care</h3>
              <p>
                Clinical excellence must be the priority for any health care
                service provider.
              </p>
              <a href={`tel:${projectPhone}`} className="boxed-btn3-white">
                {projectPhone}
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-md-4">
            <div className="single_service">
              <div className="icon">
                <i className="flaticon-first-aid-kit"></i>
              </div>
              <h3>Excellent Services</h3>
              <p>Setting the Standard for Excellence in Healthcare Services.</p>
              <Link to="/login" className="boxed-btn3-white">
                Request Appointment
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
