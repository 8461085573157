import PageTitle from "../common/pagetitle/pagetitle";
import DoctorsArea from "../homepage/section/doctors-area";
import React from "react";

export default function Doctors() {
    return (
        <>
            <PageTitle title="Doctors"/>
            <DoctorsArea/>
        </>
    )
}
